import LayoutF from "../../components/LayoutF";

const Legal = () => {
  return (
    <LayoutF>
      <div className="bg-auth">
        <div className="container pt-24">
          <h4 className="bleu800">Mentions Légales</h4>
          <p className="bleu800 subtitle-m mt-16">
            En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour
            la confiance dans l'économie numérique, il est précisé aux
            utilisateurs du site www.app.marionmateriaux.fr l'identité des
            différents intervenants dans le cadre de sa réalisation et de son
            suivi :
          </p>
          <ol>
            <h5 className="bleu800 mt-40">
              <li className="text-start">
                Propriétaire
                <p className="bleu800-body-s mt-14">
                  SAS Marion au capital de 30 000 euros - Immatriculée au RCS
                  CANNES sous le numéro Siret 987734472, ayant son siège social
                  au 559 Chemin du Hameau de Plan Sarrain - Domaine du Plan
                  Sarrain - Villa 9 - 06370 MOUANS-SARTOUX.
                </p>
              </li>
            </h5>

            <h5 className="bleu800">
              <li className="text-start">Responsable publication</li>
              <p className="bleu800-body-s mt-14">
                Sandra RONCE en sa qualité de représentante légale Téléphone:
                06.61.23.96.33 Adresse électronique: contact@marion-materiaux.fr
              </p>
            </h5>
            <h5 className="bleu800">
              <li className="text-start">Créateur</li>
              <p className="bleu800-body-s mt-14">
                LeBackyard au capital de 5 000 euros – Immatriculée au RCS PARIS
                sous le numéro Siret 819130873 ayant son siège social au 17 Bis
                Avenue Parmentier - 75011 PARIS
              </p>
            </h5>
            <h5 className="bleu800 pb-32">
              <li className="text-start">Hébergeur</li>
              <p className="bleu800-body-s mt-14">
                OVH – 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1
              </p>
            </h5>
          </ol>
        </div>
      </div>
    </LayoutF>
  );
};
export default Legal;
