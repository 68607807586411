import { Card } from "react-bootstrap";
import '../assets/css/card.scss'
import { useNavigate, useParams } from "react-router-dom";
import Services from "../services/global.service";
import { useEffect } from "react";

const CardCategory=({data})=>{
    const IMG_URL= process.env.REACT_APP_FILE_BASE_URL
    const navigate=useNavigate()
    const params=useParams()
    const handleCardClick=(id)=>{
      navigate(`/category/${id}`)
    }
 
return(
    <div className="col-md-3 mb-4 link-cursor">
    <Card
    style={{ width: '100%' }}
    className="card-marion "
    onClick={()=>handleCardClick(data?.id)}
  >
    <div className="product-image-container" style={{ backgroundImage: `url(${IMG_URL + data?.image})` }}>   
    </div> 
      <Card.Text className='ref-txt ' style={{minHeight:'68px'}}>
        <h6 className="text-center title-category">{data?.name}</h6>
      </Card.Text> 
     
  </Card>
  </div>
)
}
export default CardCategory;