import React, { useEffect, useState } from "react";
import Services from "../services/global.service";
import ReturnIcon from "../assets/imgs/fi_arrow-left.svg";
import Truck from "../assets/imgs/fi_truck_status.svg";
import download from "../assets/imgs/fi_download.svg";
import Visa from "../assets/imgs/visaSingle.svg";
import MasterCard from "../assets/imgs/masterCard.svg";
import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

const OrderDetails=()=>{
    const params=useParams()
  const [lastPaiementCard, setLastPaiementCard] = useState("");
  const [commandeDetails, setCommandeDetails] = useState();
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
const getOrderDetails=()=>{
    Services.getOne(params?.idOrder,'commandes/get')
    .then((res)=>{
        setCommandeDetails(res?.data[0])
        getLastPayment(res?.data[0]?.id_panier);
    })
    .catch((err)=>{console.log(console.log(err))})
}
  const getLastPayment = (id) => {
    Services.getAllByData(`lastpayment/${id}`, {})
      .then((res) => {
        setLastPaiementCard(res?.data?.card);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function formatStatus(status) {
    switch (status) {
      case "4":
        return "En cours de validation";
      case "1":
        return "En cours de livraison";
      case "3":
        return "En cours de préparation";
      case "2":
        return "Livré";
      case "5":
        return "Validé";
      case "6":
        return "Annulé";
      default:
        return status;
    }
  }
  useEffect(() => {
    getOrderDetails();
    
 
  }, []);
return(
    <>
    <h5 className="bleu700 mt-40 d-flex align-items-center">
      <a
        className="link-s"
        href="/profil/4"
      >
        {" "}
        <img src={ReturnIcon} alt="Icon retour" className="mr-8" />
      </a>{" "}
      Détail de la commande {commandeDetails?.reference}{" "}
    </h5>

    <div className="white-bg-card no-border text-start mt-32 p-24">
      <div className="row">
        <div className="col">
          <span className="badge-ongoing">
            <img src={Truck} alt="truck icon" className="mr-8" />
            {formatStatus(commandeDetails?.status)}{" "}
          </span>
        </div>
        <div className="col text-end">
          <a
            className="link-s "
            href={`${process.env.REACT_APP_BASE_URL}factures/${commandeDetails?.id}`}
            download
          >
            <img src={download} alt="download icon" /> Télécharger la facture
          </a>{" "}
        </div>
      </div>
      <div className="mt-32">
        <div className="d-flex ">
          <div className=" mr-40">
            {" "}
            <label className="bleu400-body-s mr-8">
              N° de commande :
            </label>{" "}
            <span className="Exergue">{commandeDetails?.reference}</span>{" "}
          </div>
          <div className="">
            {" "}
            <label className="bleu400-body-s mr-8">
              Date de commande :{" "}
            </label>{" "}
            <span className="Exergue">
              {new Date(
                commandeDetails?.created_at
              )?.toLocaleDateString()}
            </span>{" "}
          </div>
        </div>
        <div className=" mt-24">
          {" "}
          <label className="bleu400-body-s mr-8">Acheteur : </label>
          <span className="Exergue">
            {commandeDetails?.user?.nom} {commandeDetails?.user?.prenom}
          </span>{" "}
        </div>
      </div>
    </div>
    <div className="white-bg-card no-border text-start mt-24 p-24">
      <p className="bleu700 subtitle-m">Adresse de livraison</p>
      <div className="mt-32">
        <Card.Text className="ref-txt d-flex mt-32 ">
          <div className=" mr-8">
            <img src={mapIcon} alt="map icon" className="mr-8" />
          </div>
          <div className="">
            <p className="adr-name">
              {commandeDetails?.adresse_user?.name}
            </p>
            <p className="bleu600 subtitle-s mt-8">
              {commandeDetails?.adresse_user?.nom}{" "}
              {commandeDetails?.adresse_user?.prenom}
            </p>
            <p className="bleu600-body-s mt-8 mb-0">
              {commandeDetails?.adresse_user?.adresse}
            </p>
            <p className="bleu600-body-s my-0">
              {commandeDetails?.adresse_user?.telephone}
            </p>
          </div>
        </Card.Text>
      </div>
    </div>
    <div className="white-bg-card no-border text-start mt-24 mb-24 p-24">
      <div className="d-flex justify-content-between">
        <p className="bleu700 subtitle-m">
          Mes articles ({commandeDetails?.panier?.panier_items?.length})
        </p>
        <p className="bleu700 subtitle-m">{commandeDetails?.ttc} €</p>
      </div>

      <div id="my-articles ">
        {commandeDetails && commandeDetails?.panier?.panier_items
          ? commandeDetails?.panier?.panier_items?.map((item) => (
              <div className="mt-16" key={item?.id}>
                <React.Fragment>
                  <div className=" mt-8">
                    <div className="row mt-8">
                      <div
                        className="col-3 img-toggle"
                        style={{
                          backgroundImage: `url(${
                            IMG_URL +
                            item?.product_panier_item?.images[0]?.path
                          })`,
                        }}
                      ></div>
                      <div className="col-8">
                        <p className="bleu600-body-s">
                          {
                            item?.product_panier_item?.product_marque
                              ?.name
                          }
                        </p>
                        <p className="product-title">
                          {item?.product_panier_item?.titre}
                        </p>
                        <p className="legende">
                          Ref:{item?.product_panier_item?.reference}
                        </p>
                        <p className="body-m bleu800">
                          {(
                            item?.product_panier_item?.ttc *
                            +item?.quantity
                          ).toFixed(2).replace('.', ',')}{" "}
                          € TTC
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            ))
          : null}
      </div>
    </div>
    <div className="white-bg-card no-border text-start mt-32 p-24 mb-24">
      <div className="row">
        <p className="bleu700 subtitle-m">Informations de paiement</p>
        <span>
          <div className="d-flex">
            <div className="mr-8">
              <img
                src={
                  lastPaiementCard?.brand === "visa" ? Visa : MasterCard
                }
                alt="carte bleu"
              />
            </div>
            <div>
              <p className="bleu700 subtitle-m">Carte bleue</p>
              <p className="bleu700-body-s py-0 my-0">
                ****** {lastPaiementCard?.last4}
              </p>
            </div>
          </div>
        </span>
      </div>
    </div>
   
  </>
)
}
export default OrderDetails;