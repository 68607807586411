import { useEffect, useState } from 'react';
import AuthService from '../services/auth.service';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Services from '../services/global.service';

const Information = () => {
    const [info, setInfo] = useState();

    const getMyProfil = () => {
         AuthService.profil()
        .then((res) => {
            setInfo(res?.data)
        }).catch((err) => {
            console.log(err);
        });
    };
    const handleSaveInfo=(values)=>{
        Services.update(values,info?.id,'users')
        .then((res)=>{
            toast.success('Vos informations ont été mises à jour avec succès!', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
        })
        .catch((err)=>{
            console.log(err)
            toast.error('Échoué . Veuillez réessayer.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
        
        })
    }
    useEffect(() => {
       
        
        getMyProfil(); // Call the async function
    }, []);

    // Wait for info to be set before rendering the form
    if (!info) {
        return null; // Or render a loading indicator
    }

    return (
        <div className="profil min-vh-80 col-md-6 mx-auto mt-24">
            <h5>Informations personnelles</h5>
            <Formik
                initialValues={{
                    email: info?.email,
                    nom: info?.nom,
                    prenom: info?.prenom,
                    telephone: info?.user_profil?.telephone
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('Required'),
                    nom: Yup.string()
                        .required('Required'),
                    prenom: Yup.string()
                        .required('Required'),
                    telephone: Yup.string()
                        .matches(/^[0-9]{10}$/, 'Invalid phone number')
                        .required('Required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSaveInfo(values);
                    setTimeout(() => {
                        // Your submission logic here
                        setSubmitting(false);
                    }, 400);
                }}
            >
                <Form>
                    <div className='mt-32'>
                    <div className="form-floating mb-3">
                        <Field type="email" name="email"  className="form-control" id="floatingInput" placeholder=""/>
                        <label for="floatingInput">Adresse e-mail</label>
                    </div>
                        <ErrorMessage name="email" component="div" className='text-danger text-left' />
                        
                    </div>
                    
                    <div className='mt-16'>
                    <div className="form-floating mb-3">
                        <Field type="text" name="prenom" className="form-control" id="floatingInput" placeholder="" />
                        <label for="floatingInput">Prénom</label>
                    </div>
                        <ErrorMessage name="prenom" component="div" className='text-danger text-left' />
                    </div>
                    <div className='mt-16'>
                    <div className="form-floating mb-3">
                        <Field type="text" name="nom" className="form-control" id="floatingInput" placeholder=""/>
                        <label for="floatingInput">Nom</label>
                    </div>
                        <ErrorMessage name="nom" component="div" className='text-danger text-left' />
                    </div>
                    <div className='mt-16'>
                    <div className="form-floating mb-3">
                        <Field type="text" name="telephone" className="form-control" id="floatingInput" placeholder="" />
                        <label for="floatingInput">Numéro de téléphone</label>
                    </div>
                        <ErrorMessage name="telephone" component="div" className='text-danger text-left' />
                    </div>
                    <div className="text-end">
                        <button className='cta-primary-m mt-32' type="submit">Enregistrer</button>
                    </div>

                </Form>
            </Formik>
            <div className='mt-24'>
            </div>
        </div>
    );
};

export default Information;
