import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Services from "../services/global.service";
import trash from "../assets/imgs/fi_trash.svg";

const CartToggle = ({
  showOffcanvas,
  setShowOffcanvas,
  qte,
  details,
  cart,
  setCart,
  getMyCart,
  tempsRestantLivraison
}) => {
  const [qteCart, setQteCart] = useState(qte);
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;

  const handleToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  function plageHoraireLivraison(startDeliveryDateTimeString, endDeliveryDateTimeString) {
    // Extract time from start and end delivery date times
    const startTime = startDeliveryDateTimeString.split(" ")[1];
    const endTime = endDeliveryDateTimeString.split(" ")[1];

    // Format the time parts
    const startTimeFormatted = startTime.split(":")[0] + ":" + startTime.split(":")[1];
    const endTimeFormatted = endTime.split(":")[0] + ":" + endTime.split(":")[1];

    const deliveryDate = new Date(startDeliveryDateTimeString.split(" ")[0]);
    const today = new Date();
    
    // Check if delivery is scheduled for today
    if (deliveryDate.toDateString() === today.toDateString()) {
        return `Livraison aujourd’hui entre ${startTimeFormatted} et ${endTimeFormatted}`;
    } else {
        return `Livraison prévue pour ${deliveryDate.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })} entre ${startTimeFormatted} et ${endTimeFormatted}`;
    }
}
  const handleUpdateCart = (idItem, qte) => {
    
    Services.create({ quantity: qte }, `panier/update/${idItem}`) // Added comma and fixed string interpolation
      .then((res) => {

        getMyCart();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getMyCart();
  }, []);

  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${showOffcanvas ? "show" : ""}`}
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Mon panier</h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={handleToggle}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="row">
            <div className="col text-start bleu700-body-s">
              {cart?.panier_items?.reduce((total, item) => total + item?.quantity, 0)} articles
            </div>
            <div className="col mt-8 text-end bleu600-body-s">
              Total articles HT : {cart?.totalHTProduits?.toFixed(2).replace('.', ',')} €{" "}
            </div>
          </div>

          {cart?.panier_items?.map((item) => (
            <React.Fragment key={item?.id}>
              {" "}
              {/* Added key attribute */}
              <div className="row mt-8 border-top-card">
                <div
                  className="col-4 img-toggle"
                  style={{
                    backgroundImage: `url(${
                      IMG_URL + item?.product_panier_item?.images[0]?.path
                    })`,
                  }}
                >
                </div>
                <div className="col-8">
                  <p className="bleu600-body-s">
                    {item?.product_panier_item?.product_marque?.name}
                  </p>
                  <a href={`/product/${item?.product_panier_item?.id}`}>
                    <p className="product-title">
                      {item?.product_panier_item?.titre}
                    </p>
                  </a>
                  <p className="legende">
                    Ref:{item?.product_panier_item?.reference}
                  </p>
                  <p className="body-m bleu800">
                    {item?.product_panier_item?.ht.replace('.', ',')} € HT{" "}
                    <span className="bleu700-body-s">
                      ({item?.product_panier_item?.ttc?.replace('.', ',')} € TTC)
                    </span>
                  </p>
                </div>
              </div>
              <div className="row justify-content-end align-items-center">
                <div className="col-auto text-end">
                  <div className="d-flex p-1 qte-container">
                    <button
                      className="qte-btn p-2"
                      onClick={() => {
                        handleUpdateCart(item?.id, +item?.quantity - 1);
                      }}
                      disabled={item?.quantity === 0}
                    >
                      -
                    </button>
                    <button className="link-s p-2">{item?.quantity}</button>
                    <button
                      className="qte-btn  p-2"
                      onClick={() => {
                        handleUpdateCart(item?.id, +item?.quantity + 1);
                      }}
                      disabled={item?.quantity >= +item?.product_panier_item?.quantite || 
                        (item?.quantity  ===+item?.product_panier_item?.initial_quantity 
                        && item?.product_panier_item?.initial_quantity!=0 )}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    className="link-s"
                    onClick={() => {
                      handleUpdateCart(item?.id, 0);
                    }}
                  >
                    <img src={trash} alt="trash icon" />
                  </button>{" "}
                  {/* Changed onclick to onClick */}
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className="bg-auth p-16 mt-40">
            <p className="bleu700-body-s">{tempsRestantLivraison}</p>
            <div className="d-flex justify-content-between">
              <p className="subtitle-m bleu700">Total articles</p> <p className="subtitle-m bleu700">HT {cart?.totalHTProduits.toFixed(2).replace('.', ',')} €</p>
            </div>
            
          </div>
          <div className="btns mt-32">
            <div className="d-grid gap-2">
              {" "}
              <a className="cta-primary-m" size="lg" href="/panier">
                Aller à mon panier
              </a>
            </div>
            <div className="d-grid gap-2 mt-16">
              <button
                onClick={() => setShowOffcanvas(false)}
                size="lg"
                className="cta-secondary-m "
              >
                Poursuivre mes achats
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartToggle;
