import Layout from "../components/Layout";
import Liv from "../assets/imgs/liv.svg";
import CarteBleu from "../assets/imgs/cb.png";
import { CgMathPlus } from "react-icons/cg";
import "../assets/css/panier.scss";
import ReturnIcon from "../assets/imgs/fi_arrow-left.svg";
import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
import Visa from "../assets/imgs/visaSingle.svg";
import Logo from "../assets/imgs/LogoMarion.svg";
import MasterCard from "../assets/imgs/masterCard.svg";
import PaymentCards from "../assets/imgs/visa.svg";
import Paypal from "../assets/imgs/paypal.svg";
import React, { useEffect, useState } from "react";
import Services from "../services/global.service";
import { Button, Card, Collapse } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
//stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardElement from "../components/addCardElement";
import PaymentComp from "../components/paiement";
const pk=process.env.REACT_APP_STRIPE_PK
const stripePromise = loadStripe(pk);
const Paiement = () => {
  const [cart, setCart] = useState();
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [addBloc, setAddBloc] = useState(false);
  const [cardForPayment, setCardForPayment] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("carte");
  const [clientSecret, setClientSecret] = useState("");
  const [cards, setCards] = useState([]);
  const [myTour, setMyTour] = useState();
   
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [myAddress, setMyAddress] = useState([]);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    // mode: 'payment',
    //currency: 'eur',
  };
  useEffect(() => {
    stripePromise.then(() => {
      setStripeLoaded(true);
    });
  }, []);
  const PaymentIntent = () => {
    Services.create({}, "setup-intent")
      .then((res) => {
        setClientSecret(res?.data?.setupIntent);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getMyCart = () => {
    Services.getAll("panier")
      .then((res) => {
        setCart(res?.data?.panier);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyCards = () => {
    Services.getAll("getCards")
      .then((res) => {
        setCards(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMyAdresses = () => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
        const filteredAddresses = res?.data?.filter((adr) => adr.type === 2);
        setMyAddress(filteredAddresses[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTours = () => {
    Services.getAll(
      `tour-livraisons/filter-tours?id=${localStorage.getItem("tour")}`
    )
      .then((res) => {
        setMyTour(res?.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function plageHoraireLivraison(startDeliveryDateTimeString, endDeliveryDateTimeString) {
    // Extract time from start and end delivery date times
    const startTime = startDeliveryDateTimeString.split(" ")[1];
    const endTime = endDeliveryDateTimeString.split(" ")[1];

    // Format the time parts
    const startTimeFormatted = startTime.split(":")[0] + ":" + startTime.split(":")[1];
    const endTimeFormatted = endTime.split(":")[0] + ":" + endTime.split(":")[1];

    const deliveryDate = new Date(startDeliveryDateTimeString.split(" ")[0]);
    const today = new Date();
    
    // Check if delivery is scheduled for today
    if (deliveryDate.toDateString() === today.toDateString()) {
        return `Livraison aujourd’hui entre ${startTimeFormatted} et ${endTimeFormatted}`;
    } else {
        return `Livraison prévue pour ${deliveryDate.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })} entre ${startTimeFormatted} et ${endTimeFormatted}`;
    }
}


  useEffect(() => {
    getMyCart();
    getMyAdresses();
    getTours();
    PaymentIntent();
    getMyCards();
  }, []);


  return (
    <Layout header={false}>
      <header className=" d-flex  header text-start align-items-center">
        <div className="col-auto">
          {" "}
          <a href="/">
            <img src={Logo} alt="logo marion" className="" />
          </a>
        </div>
        <div className=" col d-flex justify-content-around">
          <div className="d-flex align-items-center">
            <h6 className="prog-circul" style={{ width: 66, height: 66 }}>
              <CircularProgressbar
                value={100}
                text={`1`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round", // Changed to round

                  // Text size
                  textSize: "24px",
                  textStyles: {
                    // Text color
                    fill: "#00264A", // Change the text color
                    // Font family
                    fontWeight: "700",
                    fontFamily: "Outfit-Bold", // Change the font family
                    // Additional text styles
                    // Add any additional text styles here
                  },
                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(255, 121, 113,  1)`,
                  textColor: "#00264A",
                  trailColor: "#FFF4F3",
                  backgroundColor: "#FF7971",
                })}
              />
            </h6>
            <span className="subtitle-m bleu700 pl-16">Panier</span>
          </div>
          <div className="d-flex align-items-center">
            <h6 className="prog-circul" style={{ width: 66, height: 66 }}>
              <CircularProgressbar
                value={100}
                text={`2`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.03,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round", // Changed to round

                  textSize: "24px",
                  textStyles: {
                    fill: "#00264A",
                    fontWeight: "700",
                    fontFamily: "Outfit-Bold",
                  },

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(255, 121, 113, 1)`,
                  textColor: "#00264A",
                  trailColor: "#FFF4F3",
                  backgroundColor: "#FF7971",
                })}
              />
            </h6>
            <span className="subtitle-m bleu700 pl-16">Livraison</span>{" "}
          </div>

          <div className="d-flex align-items-center">
            {" "}
            <h6 className="prog-circul" style={{ width: 66, height: 66 }}>
              <CircularProgressbar
                value={70}
                text={`3`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.03,
                  strokeLinecap: "round", // Changed to round
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

                  // Text size
                  textSize: "24px",
                  textStyles: {
                    fill: "#00264A",
                    fontWeight: "700",
                    fontFamily: "Outfit-Bold",
                  },
                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Colors
                  pathColor: `rgba(255, 121, 113, 1)`,
                  textColor: "#00264A",
                  trailColor: "#FFF4F3",
                  backgroundColor: "#FF7971",
                })}
              />
            </h6>
            <span className="subtitle-m bleu700 pl-16">Paiement</span>{" "}
          </div>
        </div>
      </header>
      {stripeLoaded && cart?.id ? (
      <Elements stripe={stripePromise}>
      {/* Your application components */}
      <PaymentComp  />
    </Elements>)
    :(
      <div className="container">
       <p className="body-s mt-32">Panier vide</p> 
      </div>
      
    )
    }
      
    </Layout>
  );
};
export default Paiement;
