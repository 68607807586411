import Layout from "../components/Layout";
import Liv from "../assets/imgs/liv.svg";
import trash from "../assets/imgs/fi_trash.svg";
import "../assets/css/panier.scss";
import Arrow from "../assets/imgs/keyboard_arrow_right_24px.svg";
import Minus from "../assets/imgs/minus.svg";
import check from "../assets/imgs/fi_check-vert.svg";
import alert from "../assets/imgs/fi_alert-triangle.svg";
import PaymentCards from "../assets/imgs/payment-cards.svg";
import React, { useEffect, useState } from "react";
import Services from "../services/global.service";
import { Button, Collapse } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment/moment";
import 'moment/locale/fr';
const Panier = () => {
  const [cart, setCart] = useState();
  const [promo_result, setPromo_result] = useState([]);
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [open, setOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [tours, setTours] = useState([]);
  const [nextTour, setNextTour] = useState();
  const [count, setCount] = useState(0);
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;

   // Function to update the remaining time
   const updateRemainingTime = (startDeliveryDateTimeString) => {
    const startDeliveryDateTime = new Date(startDeliveryDateTimeString);
    const currentDate = new Date();

    // Calculate the remaining time until the start delivery time
    const difference = startDeliveryDateTime - currentDate;

    // Check if the delivery time is in the future
    if (difference > 0) {
      // Convert milliseconds to hours and minutes
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const second = Math.floor((difference % (1000 * 60)) / 1000);

      // Update state with remaining hours and minutes
      setHoursRemaining(hours);
      setMinutesRemaining(minutes);
      setSecondsRemaining(second);
    }
  };
  useEffect(() => {
    // Example usage of the function
  if(nextTour?.livraison_heur_debut){
    const timerInterval = setInterval(() => {
      updateRemainingTime(nextTour?.livraison_heur_debut);
    }, 1); // Update the timer every second

    // Clear the interval on component unmount
    return () => clearInterval(timerInterval);
  }
    
  }, [nextTour]);

  function tempsRestantLivraison(datetime_end,startDeliveryDateTimeString) {
    // Convert the start delivery date string to a Date object
    const startDeliveryDateTime = new Date(datetime_end);

    // Get the current system date
    const currentDate = new Date();

    // Calculate the remaining time until the start delivery time
    const difference = startDeliveryDateTime - currentDate;

    // Check if the delivery time is in the future
    if (difference > 0) {
        // Convert milliseconds to hours and minutes
        const heuresRestantes = Math.floor(difference / (1000 * 60 * 60));
        const minutesRestantes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const secondsRestantes = Math.floor((difference % (1000 * 60)) / 1000);
    moment.locale('fr'); // Set the locale to French

    const deliveryDate = moment(startDeliveryDateTimeString).isSame(moment(), 'day') ? "aujourd'hui" : moment(startDeliveryDateTimeString).format('dddd');

        // Return the formatted remaining time
        return `Plus que ${heuresRestantes.toString().padStart(2, '0')}:${minutesRestantes.toString().padStart(2, '0')}:${secondsRestantes.toString().padStart(2, '0')} pour être livré ${deliveryDate} à partir de ${startDeliveryDateTime.getHours().toString().padStart(2, '0')}h:${startDeliveryDateTime.getMinutes().toString().padStart(2, '0')}`;

    } else {
        return `La date de livraison est déjà passée.`;
    }
}

  // Example usage:
const datetime_end = '2024-07-24T15:00:00'; // For example, Monday, July 22, 2024, 15:00
const startDeliveryDateTimeString = '2024-07-20T10:00:00'; // For example, Saturday, July 20, 2024, 10:00

  //get tours
  const getTours = () => {
    Services.getAll("tour-livraisons/active-tours")
      .then((res) => {
        setTours(res?.data?.tours);

        const sortedTours = res?.data?.tours
          // Filter out tours with null or undefined datetime_start
          ?.filter((tour) => tour?.datetime_start && new Date(tour?.datetime_end) > new Date())
          // Sort the tours by datetime_start
          ?.sort(
            (a, b) => new Date(a?.datetime_start) - new Date(b?.datetime_start)
          );
        // Find the next tour
        const now = new Date();
        let nextTour = null;
        for (const tour of sortedTours) {
          const tourStartDate = new Date(tour?.datetime_start);
          if (!nextTour || tourStartDate < nextTour) {
            nextTour = tour;
          }
        }

        // Log the results
        setNextTour(nextTour);

      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Exemple d'utilisation de la fonction

  const getMyCart = (action) => {
    let param = "code";
    if (action === "delete") {
      param = "delete_code_promo";
    }
    Services.getAll(`panier?${param}=${promoCode}`)
      .then((res) => {
        setCart(res?.data?.panier);
        // setPromo_result(res.data?.promo_result);
        if (action === "delete") {
          setPromoCode("");
          setPromo_result([]);
        } else {
          setPromo_result(res?.data?.promo_result);
        }      
        if (promo_result?.message) {
          // Afficher le message d'erreur du code promo
          // Mettre à jour l'état ou effectuer d'autres actions nécessaires
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUpdateCart = (idItem, qte) => {
    Services.create({ quantity: qte }, `panier/update/${idItem}`) // Added comma and fixed string interpolation
      .then((res) => {
        getMyCart();
        // setCart(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    getMyCart();
    getTours();

   // tempsRestantLivraison();
  }, []);
  return (
    <Layout header={true}>
      
      <div className="container pb-32">
        <h4 className="bleu800 pt-16">Mon panier</h4>

        {cart?.id ? (
          <div className="row mt-32">
            <div className="col-md-8">
              {" "}
              <div className="white-bg-card text-start">
              {nextTour && (<img src={Liv} alt="clock icon" className="mr-8" />) }
                <span className="bleu700 subtitle-s">
              
                  {nextTour && tempsRestantLivraison(nextTour?.datetime_end,nextTour?.livraison_heur_debut)}
                </span>
              </div>
              <div className="d-flex mt-24 justify-content-between">
                <div className="nb-article">
                {cart?.panier_items?.reduce((total, item) => total + item?.quantity, 0)}  articles
                </div>
                <div className="bleu600-body-s">
                  Vos produits sont sauvegardés pendant 24h dans votre panier
                </div>
              </div>
              {cart?.panier_items?.map((item) => (
                <div className="mt-16">
                  <React.Fragment key={item.id}>
                    {" "}
                    {/* Added key attribute */}
                    <div className="white-bg-card mt-8">
                      <div className="row mt-8 ">
                        <div
                          className="col-4 img-toggle"
                          style={{
                            backgroundImage: `url(${
                              IMG_URL +
                              item?.product_panier_item?.images[0]?.path
                            })`,
                          }}
                        ></div>
                        <div className="col-8">
                          <p className="bleu600-body-s">
                            {item?.product_panier_item?.product_marque?.name}
                          </p>
                          <a href={`/product/${item?.product_panier_item?.id}`}>
                              <p className="product-title">
                            {item?.product_panier_item?.titre}
                          </p>
                          </a>
                        
                          <p className="legende">
                            Ref:{item?.product_panier_item?.reference}
                          </p>
                          <p className="body-m bleu800">
                            {(
                              item?.product_panier_item?.ttc * +item?.quantity
                            ).toFixed(2).replace('.', ',')}{" "}
                            € TTC
                          </p>
                        </div>
                      </div>
                      <div className="row justify-content-end align-items-center">
                        <div className="col-auto text-end">
                          <div className="d-flex p-1 qte-container">
                            <button
                              className="qte-btn p-2"
                              onClick={() => {
                                handleUpdateCart(item?.id, +item?.quantity - 1);
                              }}
                              disabled={item?.quantity === 0}
                            >
                              <span className="d-flex">
                                <img src={Minus} alt="minus icon" />
                              </span>
                            </button>
                            <button className="link-s p-2">
                              {item?.quantity}
                            </button>
                            {/* <TextField id="filled-basic "  variant="filled" value={row.quantite} /> */}
                            <button
                              className="qte-btn  p-2"
                              onClick={() => {
                                handleUpdateCart(item?.id, +item?.quantity + 1);
                              }}
                            disabled={item?.quantity >= +item?.product_panier_item?.quantite || item?.quantity  ===+item?.product_panier_item?.initial_quantity}
                            >
                              <span>+</span>
                            </button>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            className="link-s"
                            onClick={() => {
                              handleUpdateCart(item?.id, 0);
                            }}
                          >
                            <img src={trash} alt="trash icon" />
                          </button>{" "}
                          {/* Changed onclick to onClick */}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              ))}
            </div>
            <div className="col-md-4">
              <div className="white-bg-card">
                <h6>Récapitulatif</h6>
                <div className="d-flex mt-32 justify-content-between">
                  <p className="bleu700-body-s">Total articles HT</p>
                  <p className="bleu700-body-s">
                    {cart?.totalHTProduits.toFixed(2).replace('.', ',')} €
                  </p>
                </div>
                {promo_result?.discount > 0 && promo_result && (
                  <div className="d-flex mt-16 justify-content-between">
                    <p className="bleu700-body-s">Réduction</p>
                    <p className="bleu700-body-s">
                      - {promo_result?.discount?.toFixed(2).replace('.', ',')} €
                    </p>
                  </div>
                )}
                <div className="d-flex mt-16 justify-content-between">
                  <p className="bleu700-body-s">Frais de livraison</p>
                  <p className="bleu700-body-s">
                    {cart?.FraisLivraison.toFixed(2).replace('.', ',') ?? 0} €
                  </p>
                </div>
                <hr className="mt-16" />
                <div className="d-flex mt-16 justify-content-between">
                  <p className="Exergue">Total HT</p>
                  <p className="Exergue">
                    {promo_result?.discount > 0 && promo_result
                      ? promo_result?.totalHT?.toFixed(2).replace('.', ',')
                      : cart?.totalHT?.toFixed(2).replace('.', ',')}{" "}
                    €
                  </p>
                </div>
                <div className="d-flex mt-16 justify-content-between">
                  <p className="bleu700-body-s ">TVA 20% </p>
                  <p className="bleu700-body-s ">{cart?.tva20.toFixed(2).replace('.', ',')} €</p>
                </div>
                <div className="d-flex mt-16 justify-content-between">
                  <p className="subtitle-m bleu700 ">Total TTC</p>
                  <p className="subtitle-m bleu700">
                    {promo_result?.discount > 0 && promo_result
                      ? promo_result?.totalTTC?.toFixed(2).replace('.', ',')
                      : cart?.totalAvecFraisdelivraison.toFixed(2).replace('.', ',')}{" "}
                    €
                  </p>
                </div>

                <div className="d-grid gap-2">
                  <a
                    className="cta-primary-m mt-32 w-100"
                    href="/livraison"
                    size="lg"
                  >
                   Choisir ma livraison
                  </a>
                </div>
              </div>
              <div className="white-bg-card mt-24">
                <div className="row ">
                  <div className="col-md-10">
                    <h6>Saisis ton code de réduction</h6>
                  </div>
                  <div className="col-md-2">
                    <Button
                      className="link-s"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      <img src={Arrow} alt="arrow icon" />
                    </Button>
                  
                  </div>

                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <input
                        type="text"
                        className={
                          promo_result.discount > 0
                            ? "form-control mt-8"
                            : promo_result?.discount < 0
                            ? "form-control mt-8 danger-input"
                            : "form-control mt-8"
                        }
                        value={promoCode}
                        placeholder="Ton code de réduction"
                        onChange={(e) => setPromoCode(e.target.value)}
                      />
                      <button
                        className="cta-primary-m mt-24 w-100"
                        onClick={getMyCart}
                      >
                        Appliquer
                      </button>
                    
                      {promo_result  && promo_result?.length !== 0 && (
                        <p
                          className={`mt-8 ${
                            promo_result.discount > 0
                              ? "success-text"
                              : "danger-text"
                          }`}
                        >
                          <img
                            src={promo_result?.discount > 0 ? check : alert}
                            className="mr-8"
                            alt="icon alert ou check"
                          />
                          {promo_result?.discount > 0
                            ? "Code promo appliqué "
                            : "Ton code promo n’est pas applicable"}{" "}
                        </p>
                      )}
                      {promo_result?.discount > 0 && (
                        <button
                          className="link-s bleu500 mt-40"
                          onClick={() => getMyCart("delete")}
                        >
                          Effacer le code promo
                        </button>
                      )}
                    </div>
                  </Collapse>
                </div>
                {promo_result && promo_result.length !== 0 && !open && (
                        <p
                          className={`mt-8 ${
                            promo_result?.discount > 0
                              ? "success-text"
                              : "danger-text"
                          }`}
                        >
                          <img
                            src={promo_result?.discount > 0 ? check : alert}
                            className="mr-8"
                            alt="icon alert ou check"
                          />
                          {promo_result?.discount > 0
                            ? "Code promo appliqué "
                            : "Ton code promo n’est pas applicable"}{" "}
                        </p>
                      )}
              </div>
              <div className="white-bg-card mt-24 text-start">
                <h6>Moyen de paiement disponibles</h6>
                <img src={PaymentCards} alt="payment icon" className="mt-24 " />
              </div>
            </div>
          </div>
        ) : (
          <p className="body-s">Panier vide</p>
        )}
      </div>
    </Layout>
  );
};
export default Panier;
