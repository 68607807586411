import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ErrorIcon from '../assets/imgs/fi_alert-triangle.svg';
import Services from "../services/global.service";

const FormSupplier = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    clientName: Yup.string().required('Client name is required'),
    telephone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
    comment: Yup.string(),
    deliveryDateTime: Yup.string().required('Delivery date and time are required'),
    deliveryReceipt: Yup.mixed().required('Delivery receipt PDF is required').test(
      'fileSize',
      'File too large',
      value => value && value.size <= 25000000
    ),
  });

  const [address, setAddress] = useState("");
  const [pickupAdr, setPickUpAdr] = useState("");
  const [file, setFile] = useState(null);
  const [Error, setError] = useState("");
  const [deliveryLatLng, setDeliveryLatLng] = useState(null);
  const [pickupLatLng, setPickupLatLng] = useState(null);

  const HandleSave = (values) => {
    setError('')
    if (!address || !deliveryLatLng?.lat) {
      setError('Adresse de livraison requise');
      return;
    }
    
    if (!pickupAdr || !pickupLatLng?.lat) {
      setError('Adresse de pick-up requise');
      return;
    }

    const data = {
      delivery: {
        address: address,
        latitude: deliveryLatLng?.lat,
        longitude: deliveryLatLng?.lng,
      },
      pickup: {
        address: pickupAdr,
        latitude: pickupLatLng?.lat,
        longitude: pickupLatLng?.lng,
      },
      // Include other form data here as needed
    };
const myFormData=new FormData()
myFormData.append('file',file)
myFormData.append('id_fournisseur',localStorage.getItem('id'))
myFormData.append('adressPickUp',pickupAdr)
myFormData.append('email',values.email)
myFormData.append('adresseLivraison',address)
myFormData.append('dateLivraison',values.deliveryDateTime)
myFormData.append('tel',values.telephone)
myFormData.append('raisonSocial',values.steName)
myFormData.append('nomClient',values.clientName)
myFormData.append('longitudePickUp',pickupLatLng.lat)
myFormData.append('altitudePickUp',pickupLatLng.lng)
myFormData.append('altitudeLivraison',deliveryLatLng.lat)
myFormData.append('longitudeLivraison',deliveryLatLng.lng)
myFormData.append('commantaire',values.comment)
Services.create(myFormData,'fo/addDemandeCommandeFournisseur')
.then((res)=>{
})
.catch((err)=>{
  console.log(err)
})
    // Add your save logic here
  };

  const handleChangeAddress = (address, type) => {
    if (type === "pickup") {
      setPickUpAdr(address);
    } else {
      setAddress(address);
    }
  };

  const handleSelect = async (address, type) => {
    if (type === "pickup") {
      setPickUpAdr(address);
    } else {
      setAddress(address);
    }

    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0]?.address_components;

      // Extract necessary address components
      const postalCode = addressComponents.find(component => 
        component?.types?.includes('postal_code')
      );
      const street = addressComponents.find(component => 
        component?.types?.includes('route')
      );

      if (postalCode && street) {
        const latLng = await getLatLng(results[0]);
        if (latLng) {
          if (type === "pickup") {
            setPickupLatLng(latLng);
          } else {
            setDeliveryLatLng(latLng);
          }
        } else {
          console.error("Error: Unable to get latitude and longitude");
        }
      } else {
        console.error('Error: Incomplete address. Missing postal code or street.');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  };

  return (
    <div>
      <h2>Commande fournisseur</h2>
      <Formik
        initialValues={{
          email: "",
          clientName: "",
          steName: "",
          telephone: "",
          comment: "",
          deliveryDateTime: "",
          deliveryReceipt: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          HandleSave(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, setFieldValue, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="mt-40">
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    id="floatingInputemail"
                    placeholder=""
                  />
                  <label htmlFor="floatingInputemail">Adresse e-mail</label>
                  {touched.email && errors.email && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.email}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>

            <div className="row mt-16">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Autocomplete
                    value={address}
                    searchOptions={searchOptions}
                    onChange={(addr) => handleChangeAddress(addr, "liv")}
                    onSelect={(addr) => handleSelect(addr, "liv")}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="form-floating mb-3">
                        <input
                          {...getInputProps({
                            placeholder: "",
                            className: `form-control ${Error !== "" ? "danger-input" : ""}`
                          })}
                          id="floatingAdr"
                        />
                        <label htmlFor="floatingAdr">Adresse Livraison</label>
                        <div
                          className={
                            suggestions.length > 0 && `container-autocomplete`
                          }
                        >
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41B6E6"
                                : "#fff",
                            };
                            return (
                              <div
                                className="mapItem"
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Autocomplete>
                  <div className="text-danger-custom">{Error}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Autocomplete
                    value={pickupAdr}
                    searchOptions={searchOptions}
                    onChange={(addr) => handleChangeAddress(addr, "pickup")}
                    onSelect={(addr) => handleSelect(addr, "pickup")}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="form-floating mb-3">
                        <input
                          {...getInputProps({
                            placeholder: "",
                            className: `form-control ${Error !== "" ? "danger-input" : ""}`
                          })}
                          id="floatingPickupAdr"
                        />
                        <label htmlFor="floatingPickupAdr">Adresse Pick up</label>
                        <div
                          className={
                            suggestions.length > 0 && `container-autocomplete`
                          }
                        >
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41B6E6"
                                : "#fff",
                            };
                            return (
                              <div
                                className="mapItem"
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Autocomplete>
                  <div className="text-danger-custom">{Error}</div>
                </div>
              </div>
            </div>

            <div className="row mt-16">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="clientName"
                    className={`form-control ${touched.clientName && errors.clientName ? "danger-input" : ""}`}
                    id="floatingInputClientName"
                    placeholder=""
                  />
                  <label htmlFor="floatingInputClientName">Nom client</label>
                  {touched.clientName && errors.clientName && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.clientName}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="steName"
                    className={`form-control ${touched.steName && errors.steName ? "danger-input" : ""}`}
                    id="floatingInputsteName"
                    placeholder=""
                  />
                  <label htmlFor="floatingInputsteName">Nom entreprise</label>
                  {touched.steName && errors.steName && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.steName}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="text"
                    name="telephone"
                    className={`form-control ${touched.telephone && errors.telephone ? "danger-input" : ""}`}
                    id="floatingTelephone"
                    placeholder=""
                  />
                  <label htmlFor="floatingTelephone">Telephone</label>
                  {touched.telephone && errors.telephone && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.telephone}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-16">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    type="datetime-local"
                    name="deliveryDateTime"
                    
                    className={`form-control ${touched.deliveryDateTime && errors.deliveryDateTime ? "danger-input" : ""}`}
                    id="floatingDeliveryDateTime"
                    placeholder=""
                  />
                  <label htmlFor="floatingDeliveryDateTime">Date et Heure de livraison</label>
                  {touched.deliveryDateTime && errors.deliveryDateTime && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.deliveryDateTime}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <Field
                    as="textarea"
                    name="comment"
                  
                    className="form-control"
                    id="floatingComment"
                    placeholder=""
                  />
                  <label htmlFor="floatingComment">Commentaire</label>
                </div>
              </div>
            </div>

            <div className="row mt-16">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    id="file"
                    name="deliveryReceipt"
                      accept=".pdf"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("deliveryReceipt", event?.currentTarget?.files[0]);
                      setFile(event?.currentTarget?.files[0]);
                    }}
                    className={`form-control ${touched.deliveryReceipt && errors.deliveryReceipt ? "danger-input" : ""}`}
                  />
                  {touched.deliveryReceipt && errors.deliveryReceipt && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.deliveryReceipt}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <button type="submit" className="cta-primary-m" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSupplier;
