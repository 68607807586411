import { useEffect, useState } from 'react';
import AuthService from '../services/auth.service';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Services from '../services/global.service';
import Autocomplete, {
    geocodeByAddress,
    getLatLng,
  } from "react-places-autocomplete";
const InformationEntreprise = () => {
    const [info, setInfo] = useState();
    const [address, setAddress] = useState("");
    const [latLong, setLatLong] = useState({});
    const [adrSiege, setAdrSiege] = useState(null);
    const handleChangeAddress = (address) => {
        setAddress(address);
      };
      const handleSelect = async (address, setFieldValue) => {
        setAddress(address);
        setFieldValue("adresse", address);
        try {
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);
          if (latLng) {
            setLatLong(latLng);
          } else {
            console.error("Error: Unable to get latitude and longitude");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
    const getMyProfil = () => {
        AuthService.profil().then((res) => {
            setInfo(res?.data)
            setAdrSiege(res?.data?.user_adresses?.filter((elt) => elt.type == 3)[0]);
        
            const adrSiege=res?.data?.user_adresses?.filter((elt) => elt.type == 3)[0];
            setLatLong({lat:adrSiege?.latitude,lng:adrSiege?.longitude})
            setAddress(adrSiege?.adresse ?? null)
        }).catch((err) => {
            console.log(err);
        });
    };
    const handleSaveInfo=(values)=>{
        const latLongVal = {
            latitude: latLong?.lat,
            longitude: latLong?.lng,
        };
        const adressData = {
            adresse:address,
            ...latLongVal,
            type: 3,
            prenom:info?.prenom,
            nom:info?.nom,
            telephone:info?.user_profil?.telephone,
            user: info?.id,
            name: 'Adresse entreprise',
          };
        if (!adrSiege) Services.create(adressData,'adresses/create');
        else {
          
          Services.updatewithoutID({...adressData, id:adrSiege?.id}, 'adresses')
          .then((res) => {
          })
          .catch((err) => console.log(err));
        }
        Services.update(values,info.id,'users')
        .then((res)=>{
          toast.success('Vos informations ont été mises à jour avec succès!', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((err)=>{
          console.log(err)
          toast.error('Échoué . Veuillez réessayer.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
    }
    useEffect(() => {
        getMyProfil();
    }, []);

    // Wait for info to be set before rendering the form
    if (!info) {
        return null; // Or render a loading indicator
    }
    const searchOptions = {
      componentRestrictions: { country: ['fr'] },
      types: ['address']
    }
    return (
        <div className="profil min-vh-80 col-md-6 mx-auto mt-24">
            <h5>Informations entreprise</h5>
            <Formik
                initialValues={{
                    societe: info?.user_profil?.societe,
                    siret: info?.user_profil?.siret,
                    tva: info?.user_profil?.tva,
                    adresse: address
                }}
                enableReinitialize 
                validationSchema={Yup.object({
                        societe: Yup.string()
                        .required("Société est requise"),
                        siret: Yup.string()
                        .required("SIREN est requis")
                        .matches(/^FR\d{11}$/, "Champ invalide, le n° de SIREN doit comporter la mention \"FR\" suivi de 11 chiffres"),    
                        adresse: Yup.string()
                        .required("Adresse est requise"), 
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSaveInfo(values);
                    setTimeout(() => {
                        // Your submission logic here
                        setSubmitting(false);
                    }, 400);
                }}
            >
              {({ setFieldValue }) => (
                <Form>
                    <div className='mt-32'>
                    <div className="form-floating mb-3">
                    <Field type="text" name="societe" className="form-control" id="floatingInput" placeholder=""/>
                    <label for="floatingInput">Nom de la société</label>
                    </div>
                        <ErrorMessage name="societe" component="div" className='text-danger text-left' />
                    </div>
                    <div className='mt-16'>
                    <div className="form-floating mb-3">
                    <Autocomplete
                      value={address}
                      className=""
                      onChange={handleChangeAddress}
                      onSelect={(address) => handleSelect(address, setFieldValue)}
                      onPlaceSelected={(place) => {
                      }}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="form-floating mb-3">
                           <input
                    {...getInputProps({
                      placeholder: "",
                      className:`form-control`
                    })}
                    id="floatingAdr"
                  />
                    <label htmlFor="floatingAdr">Adresse du siège social</label>
                          <div
                            className={suggestions &&
                              suggestions.length > 0 && `container-autocomplete`
                            }
                          >
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41B6E6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  className="mapItem"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Autocomplete>
                    </div>
                        <ErrorMessage name="adresse" component="div" className='text-danger text-left' />
                    </div>
                    <div className='mt-16'>
                    <div className="form-floating mb-3">
                    <Field type="text" name="siret" className="form-control" id="floatingInput" placeholder=""/>
                    <label for="floatingInput">Code SIREN</label>
                    </div>
                        <ErrorMessage name="siret" component="div" className='text-danger text-left' />
                    </div>
                  
                    <div className='mt-16'>
                    <div className="form-floating mb-3">
                    <Field type="text" name="tva" className="form-control" id="floatingInput" placeholder=""/>
                    <label for="floatingInput">TVA</label>
                    </div>
                        <ErrorMessage name="tva" component="div" className='text-danger text-left' />
                    </div>
                    <div className="text-end">
                        <button className='cta-primary-m mt-32' type="submit">Enregistrer</button>
                    </div>

                </Form>
              )}
            </Formik>
            <div className='mt-24'>
            </div>
        </div>
    );
};

export default InformationEntreprise;
