import React, { useRef, useState, useEffect } from 'react';
import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import Services from "../../services/global.service";
import './auth.scss'
import LayoutF from '../../components/LayoutF';
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [afterSend, setAfterSend] = useState(false);
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const sendMail = () => {
    //service reset password in success display success bloc
    Services.create({email:email},'reset')
    .then((res)=>{
      console.log(res)
      setAfterSend(true);
    })
    .catch((err)=>{
      console.log(err)
      setError('Email introuvable')
    })
   
  };
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    
];
  function handleInput(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    // Update code state with single digit
    const newCode = [...code];
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
        const uc = input.value.toUpperCase();
        newCode[index] = uc;
        inputRefs[index].current.value = uc;
    } else {
        newCode[index] = input.value;
    }
    setCode(newCode.join(''));

    input.select();

    if (input.value === '') {
        // If the value is deleted, select previous input, if exists
        if (previousInput) {
            previousInput.current.focus();
        }
    } else if (nextInput) {
        // Select next input on entry, if exists
        nextInput.current.select();
    }
    console.log('code', code)
}

// Select the contents on focus
function handleFocus(e) {
    e.target.select();
}

// Handle backspace key
function handleKeyDown(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
        e.preventDefault();
        setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
        if (previousInput) {
            previousInput.current.focus();
        }
    }
}

// Capture pasted characters
const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData('text');
    if (pastedCode.length === 6) {
        setCode(pastedCode);
        inputRefs.forEach((inputRef, index) => {
            inputRef.current.value = pastedCode.charAt(index);
        });
    }
};
const handleConfirmCode=()=>{
  Services.create({email:email,code:code},'verify-reset-code')
  .then((res)=>{
    if (res?.data?.success) {
      navigate("/changer-mot-de-passe", { state: { email, code } });
    } 
  })
  .catch((err)=>{console.log(err)})
}
  return (
    <Layout header={true}>
      {afterSend ? (
        <div className="row m-0 pt-24 ">
          <div className="col-md-4"></div>
          <div className="col-md-4 p-24 reset-form">
          <h4 className='bleu600'>Valide ton adresse e-mail en utilisant le code envoyé</h4>
            <p className=" bleu600">
            Le code permettant de réinitialiser ton mot de passe à été envoyé à {email}. Si tu ne le vois pas vérifie dans tes spams.
            </p>
            <div className="row gap-2 relative w-50 mx-auto">
            {[0, 1, 2, 3].map((index) => (
                <input
                    className="col code-input text-2xl bg-gray-800 w-10 flex p-2 text-center"
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    autoFocus={index === 0}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}  
                />
            ))}
           
        </div>
          <div className="mt-24 "><button className="cta-primary-m w-100 " onClick={handleConfirmCode}>
              Confirmer
            </button></div>  
          </div>
          <div className="col-md-4"></div>
        </div>
      ) : (
        <div className="row m-0 pt-60 ">
          <div className="col-md-4"></div>
          <div className="col-md-4 p-24 reset-form">
            <h4 className='bleu600'>Mot de passe oublié</h4>
            <p className=" bleu600">
              Confirme ton e-mail et nous t’enverrons un e-mail pour
              réinitialiser ton mot de passe.
            </p>
            <div className="form-floating mb-3">
            <input
              type="email"
              placeholder=""
              className="form-control mt-32"
              value={email}
              id="floatingInput"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
             <label htmlFor="floatingInput">Adresse e-mail</label>
             </div>
             <div className="text-danger">{error}</div>
            <button className="cta-primary-m w-100 mt-24" onClick={sendMail}>
              Envoyer le code
            </button>
          </div>
          <div className="col-md-4"></div>
        </div>
      )}
    </Layout>
  );
};

export default ResetPassword;
