import { Card } from "react-bootstrap";
import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
import DeleteIcon from "../assets/imgs/fi_trash.svg";
import "../assets/css/card.scss";
import EditIcon from "../assets/imgs/fi_edit-3.svg";
import Services from "../services/global.service";
import { useEffect, useState } from "react";
import UpdateAddress from "./updateAdr";

const CardAdr = ({ adr, getMyAdresses }) => {
  const [updateModal, setUpdateModal] = useState(false);

  const DeleteAdr = () => {
    Services.deleteByid(adr?.id, 'adresses/delete')
      .then((res) => {
        getMyAdresses(); // Make sure this is a function passed as a prop
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
      getMyAdresses()
  
  }, [updateModal]);

  return (
    <div className="col-md-10 mb-4">
      <UpdateAddress data={adr} modalAdr={updateModal} setModalAdr={setUpdateModal} />
      <Card style={{ width: "100%" }} className="card-adr">
        <Card.Text className="ref-txt row">
          <div className="col-1 mr-8">
            <img src={mapIcon} alt="map icon" className="mr-8" />
          </div>
          <div className="col">
            <p className="adr-name">{adr?.name}</p>
            <p className="bleu600-body-s mt-8">
              {adr?.nom} {adr?.prenom}
            </p>
            <p className="bleu600-body-s mt-8">{adr?.adresse}</p>
            <div className="text-end">
              <button className="link-s" onClick={() => { setUpdateModal(!updateModal); }}>
                <img src={EditIcon} alt="edit icon" className="mr-8" />
              </button>
              {adr?.type === 1 && (
                <button className="link-s" onClick={DeleteAdr}>
                  <img src={DeleteIcon} alt="delete icon" />
                </button>
              )}
            </div>
          </div>
        </Card.Text>
      </Card>
    </div>
  );
};

export default CardAdr;
