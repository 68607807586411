import Logo from "../assets/imgs/LogoMarion.svg";
import Fb from "../assets/imgs/ic_round-facebook.svg";
import Linkedin from "../assets/imgs/mdi_linkedin.svg";
import Insta from "../assets/imgs/ri_instagram-fill.svg";
import AppStore from "../assets/imgs/appStore.svg";
import GooglePlay from "../assets/imgs/googlePlay.svg";
import "../assets/css/footer.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  const url = process.env.REACT_APP_BASE_URL_ADMIN;
  return (
    <footer>
      <div className="text-start">
        <img src={Logo} alt="Logo marion" />
      </div>
      <div className="row justify-content-between mt-40 social">
        <div className="col-auto">
          <a href="https://www.linkedin.com/company/marionmateriaux/" rel="noreferrer" target="_blank"><img src={Linkedin} alt="Linkedin icon" className="mr-24" /></a>
          <img src={Fb} alt="fb icon" className="mr-24" />{" "}
          <a href="https://www.instagram.com/marion.materiaux/" rel="noreferrer" target="_blank"><img src={Insta} alt="Insta icon" className="mr-24" /></a>
        </div>
        <div className="col-auto">
          <a href="https://apps.apple.com/us/app/marion-mat%C3%A9riaux/id6496357391" rel="noreferrer" target="_blank"><img src={AppStore} alt="AppStore icon" className="mr-24" /></a>{" "}
          <a href="https://play.google.com/store/apps/details?id=com.marionmateriaux.marion" rel="noreferrer" target="_blank"><img src={GooglePlay} alt="GooglePlay icon" className="mr-24" /></a>
        </div>
      </div>
      <div className="row col-12 mt-40 justify-content-between">
        <div className="col-auto">
          <Link className="bleu600-body-s" target="_blank" to={"https://www.marion-materiaux.fr/a-propos/"}>
            A propos de Marion
          </Link>
        </div>
        <div className="col-auto">
          <Link className="bleu600-body-s" to={"/legal"}>
            Mentions légales
          </Link>
        </div>
        <div className="col-auto">
          <Link className="bleu600-body-s" to={"/cgv"}>
            CGV
          </Link>
        </div>
        <div className="col-auto">
          <Link className="bleu600-body-s" to={"/cgu"}>
            CGU
          </Link>
        </div>
        <div className="col-auto">
          <Link className="bleu600-body-s" to={"/politique"}>
            Politique de confidentialité
          </Link>
        </div>
        <div className="col-auto">
          <Link className="bleu600-body-s" target="_blank" to={"https://www.marion-materiaux.fr/nous-contacter/"}>
            Contact
          </Link>
        </div>
        <div className="col-auto positionFo-cta col-auto-resp">
          <Link className="cta-founisseur" target="_blank" to={url}>
          Espace Fournisseur
          </Link>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
