import { useEffect, useState } from "react";
import LayoutF from "../../components/LayoutF";
import CardCategory from "../../components/cardCategory";
import Crumb from "../../components/crumb";
import Services from "../../services/global.service";
import { useParams } from "react-router-dom";
import Filtre from "../../components/filtre";
import CardProduct from "../../components/cardProduct";
import AllFilter from "../../components/allFilter";
import Next from "../../assets/imgs/keyboard_arrow_right_24px.svg";
import FiltreOptions from "../../components/filtreOptions";
const CatalogueCategory = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [checkedOptions, setCheckedOptions] = useState({});
  const [selectedMarques, setSelectedMarques] = useState([]);
  const [count, setCount] = useState(0);
  const [type, setTypes] = useState("category");
  const [isChecked, setIsChecked] = useState(false);
  const [body, setBody] = useState({});
  const [breadCrumb, setBreadCrumb] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [openFiltreOptions, setOpenFiltreOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortingOption, setSortingOption] = useState("");
  const [perPage, setPerPage] = useState(10); // Number of items per page
  const perPageOptions = [5, 10, 20, 50];
  const handleNextPage = () => {
   
    if (currentPage < totalPages) {
      
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleValider = () => {
    // Filter out options with empty values
    const filteredOptions = Object.fromEntries(
      Object.entries(selectedOptions).filter(
        ([key, value]) => Array.isArray(value) && value.length > 0
      )
    );
    const { marque, ...remainingOptions } = filteredOptions;
    const marqueString = marque ? marque.join(",") : null;
   // setBody({options:transformedOptions})
   setBody({
    ...(marque && { marque:marqueString}),
    options: remainingOptions,
    ...(sortingOption === 'asc' || sortingOption === 'desc' ? { tri: sortingOption } : {}),
    });
   // getSubCategories()
  };

  
  
  //get subcategories by id categories(params) if there is no subcategorie get product with id category and rerender data
  const getSubCategories = () => {
    Services.getOne(params?.id, "categories")
      .then((res) => {
        // Check if there are subcategories available
        if (res?.data && res?.data?.length > 0) {
          setData(res?.data);
          setTypes("category");
          
        } else {
          //If there are no subcategories, fetch products with the category ID
          const newBody= {...body,per_page:perPage,page:currentPage ,tri:sortingOption,stock:isChecked}
          Services.create(newBody,
            `products/category/${params.id}/get`
          )
            .then((res) => {
              setData(res?.data?.products);
              setTypes("products");
              setTotalPages(res?.data?.pagination?.last_page);
            })
            .catch((error) => {
              console.error("Error fetching products:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      });
  };

  
  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleDeleteMarque = (marqueToDelete) => {
    setSelectedMarques((prevMarques) =>
      prevMarques.filter((marque) => marque?.name !== marqueToDelete)
    );
    setCount(count - 1);
  };
  const handleDeleteOption = (optionName, valueToDelete) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: prevOptions[optionName].filter(
        (value) => value !== valueToDelete
      ),
    }));
    handleValider()
    setCount(count - 1);
  };
  const getBreadCrumbs=()=>{
    Services.getOne(params?.id,'categories/child')
    .then((res)=>{
      setBreadCrumb(res?.data)})
    .catch((err)=>console.log(err))
  }
  useEffect(() => {
    // Call navigate function here
    
}, [])
  useEffect(() => {
   
    getSubCategories();
    getBreadCrumbs();
  }, [params?.id,  body,perPage, currentPage,isChecked]);
  return (
    <LayoutF>
      {filterModal && (
        <AllFilter
          filterModal={filterModal}
          setFilterModal={setFilterModal}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          selectedMarques={selectedMarques}
          setSelectedMarques={setSelectedMarques}
          handleValider={handleValider}
          count={count}
          setCount={setCount}
          checkedOptions={checkedOptions}
          setCheckedOptions={setCheckedOptions}
        />
      )}
      {openFiltreOptions && (
        <FiltreOptions
          openFiltreOptions={openFiltreOptions}
          setOpenFiltreOption={setOpenFiltreOption}
          selectedMarques={selectedMarques}
          selectedOptions={selectedOptions}
          handleDeleteMarque={handleDeleteMarque}
          handleDeleteOption={handleDeleteOption}
          setSelectedOptions={setSelectedOptions}
          setSelectedMarques={setSelectedMarques}
          setCount={setCount}
        />
      )}
      <div className="bg-auth min-vh-100">
        <div className="container">
       {breadCrumb && <Crumb
          breadcrumb={breadCrumb}
          />}   
          <h4 className="bleu800 mt-24">{type==="products" ? breadCrumb[0]?.name :data[0]?.product_category?.name}</h4>
          {type === "products" && (
            <Filtre
              filterModal={filterModal}
              setFilterModal={setFilterModal}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              selectedMarques={selectedMarques}
              setSelectedMarques={setSelectedMarques}
              handleValider={handleValider}
              sortingOption={sortingOption}
              setSortingOption={setSortingOption}
              count={count}
              setCount={setCount}
              setOpenFiltreOption={setOpenFiltreOption}
              openFiltreOptions={openFiltreOptions}
              handleDeleteMarque={handleDeleteMarque}
              handleDeleteOption={handleDeleteOption}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          )}
          <div className="row mt-32">
            {data&&
              data?.map((item) =>
                type === "category" ? (
                  <CardCategory key={item?.id} data={item} />
                ) : (
                  <>
                    {" "}
                    <CardProduct key={item?.id} data={item} />
                  </>
                )
              )}
            {type === "products" && data?.length>0  ? 
            (
              <div className="row d-flex mb-5 ">
                <div className="col-6 text-start">
                  <select value={perPage} onChange={handlePerPageChange}>
                    {perPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>{" "}
                  éléments par page
                </div>
                <div className="col-6 text-end">
                  {" "}
                  <span>
                    {" "}
                    {currentPage} / {totalPages}
                  </span>
                  <button
                    onClick={handlePrevPage}
                    className={`link-s closed ${
                      currentPage === 1 ? "" : "link-pag"
                    }`}
                    disabled={currentPage === 1}
                  >
                    <img src={Next} alt="icon pagination" />
                  </button>
                  <button
                    onClick={handleNextPage}
                    className={`link-s ${
                      currentPage === totalPages ? "" : "link-pag"
                    }`}
                    disabled={currentPage === totalPages}
                  >
                    <img src={Next} alt="icon pagination" />
                  </button>
                </div>
              </div>
            ):type === "products" ? (
              <h5>Aucun produit disponible</h5>
            ) : null}
          </div>
        </div>
      </div>
    </LayoutF>
  );
};
export default CatalogueCategory;
