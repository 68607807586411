import { useEffect, useMemo, useState } from "react";
import Datatables from "./datatables";
import download from "../assets/imgs/fi_download.svg";
import Services from "../services/global.service";

const MesCommandes = () => {
  const [myOrders, setMyOrders] = useState([]);
  const [endPoint, setEndPoint] = useState('commandes/filter?');
  const getMyOrders = () => {
    Services.getAll(endPoint)
      .then((res) => {
        setMyOrders(res?.data?.commandes);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    getMyOrders();
  }, [endPoint]);
  function formatStatus(status) {
    switch (status) {
      case "4":
        return "En cours de validation";
      case "1":
        return "En cours de livraison";
      case "3":
        return "En cours de préparation";
      case "2":
        return "Livré";
      case "5":
        return "Validé";
      case "6":
        return "Annulé";
      default:
        return status;
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date de commande",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleDateString("fr-FR"),
      },
      { Header: "N° de commande", accessor: "reference" },
      {
        Header: "Acheteur",
        accessor: "user",
        Cell: ({ value }) => `${value?.nom} ${value?.prenom}`, // Concatenate nom and prenom },
      },
      // Address Information
      { Header: "Adresse de livraison", accessor: "adresse_user.adresse" },

      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ value }) => formatStatus(value)
      },
      {
        Header: "Total HT",
        accessor: "ht",
        Cell: ({ value }) => (
          <span>{parseFloat(value).toFixed(2).replace('.', ',')} €</span>
        ),
      },
      {
        Header: "Facture",
        accessor: "id",
        Cell: ({ value, row }) => (
          <a className="link-s" href={`${process.env.REACT_APP_BASE_URL}factures/${row.original.id}/${localStorage.getItem('token')}`} download target="_blank">
            <img src={download} alt="download icon" />
          </a>
        ),
      },
      // Add more columns from the user_adresses array if needed
    ],
    [] // Add dependencies if needed
  );

  return (
    <div className="profil min-vh-80 container mx-auto mt-24">
    
      <Datatables
        //title="Liste des commandes"
        setEndPoint={setEndPoint}
        endPoint={endPoint}
        columns={columns}
        data={myOrders}
      />
    </div>
  );
};
export default MesCommandes;
