
import Footer from "../components/footer";
import Header from "../components/header";

const Layout = (props) => {
return(
    <div className="min-vh-10">
   
        {props?.header===true && <Header />}
       <div className="col-12 min-vh-60  bg-auth">
        {props?.children}
        </div> 
        <Footer/>
    </div>

)
}
export default Layout;