import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services/global.service";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Route, Redirect } from 'react-router-dom';
import Crumb from "../../components/crumb";
import LayoutF from "../../components/LayoutF";
import "./catalogue.scss";
import Liv from "../../assets/imgs/fi_truck.svg";
import file from "../../assets/imgs/fi_file.svg";
import download from "../../assets/imgs/fi_download.svg";
//import assets
import Like from "../../assets/imgs/fi_heart-details.svg";
import Check from "../../assets/imgs/fi_check-circle.svg";
import Share from "../../assets/imgs/fi_share.svg";
import CartToggle from "../../components/cartToggle";
import ModalAdr from "../../components/modalAdr";
import AccessibleOrNot from "../../components/zoneAccessibleOrNot";
import { useMyContext } from "../../context";
import moment from "moment/moment";
import 'moment/locale/fr';
import { Button } from "react-bootstrap";
const DetailsProduct = () => {
  const params = useParams();
  const [details, setDetails] = useState();
  const [productInCart, setProductInCart] = useState(0);
  const [breadcrumbs, setBreadCrumbs] = useState();
  const [desc, setDesc] = useState();
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [docs, setDocs] = useState([]);
  const [qte, setQte] = useState(1);
  const [connected, setConnected] = useState(false);
  const [cart, setCart] = useState();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [accessibleModal, setAccessibleModal] = useState(false);
  const [check, setCheck] = useState(true);
  const [modalAdr, setModalAdr] = useState(false);
  const [isPriceBlockFixed, setIsPriceBlockFixed] = useState(false);
  const [nextTour, setNextTour] = useState();
  const [myAddress, setMyAddress] = useState(false);
  const { setCartData } = useMyContext(); 
  const navigate=useNavigate()
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const getTours = () => {
    Services.getAll("tour-livraisons/active-tours")
      .then((res) => {
        const sortedTours = res?.data?.tours
          // Filter out tours with null or undefined datetime_start
          .filter((tour) => tour?.datetime_start && new Date(tour?.datetime_end) > new Date())
          // Sort the tours by datetime_start
          .sort(
            (a, b) => new Date(a.datetime_start) - new Date(b.datetime_start)
          );

        // Find the next tour
        const now = new Date();
        let nextTour = null;
        for (const tour of sortedTours) {
          const tourStartDate = new Date(tour?.datetime_start);
          if (!nextTour || tourStartDate < nextTour) {
            nextTour = tour;
          }
        }
        // Log the results
        setNextTour(nextTour);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateRemainingTime = (startDeliveryDateTimeString) => {
    const startDeliveryDateTime = new Date(startDeliveryDateTimeString);
    const currentDate = new Date();

    // Calculate the remaining time until the start delivery time
    const difference = startDeliveryDateTime - currentDate;

    // Check if the delivery time is in the future
    if (difference > 0) {
      // Convert milliseconds to hours and minutes
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const second = Math.floor((difference % (1000 * 60)) / 1000);

      // Update state with remaining hours and minutes
      setHoursRemaining(hours);
      setMinutesRemaining(minutes);
      setSecondsRemaining(second);
    }
  };
  useEffect(() => {
    // Example usage of the function
   
    if(nextTour?.livraison_heur_debut){
    const timerInterval = setInterval(() => {
      updateRemainingTime(nextTour?.livraison_heur_debut);
    }, 1); // Update the timer every second

    // Clear the interval on component unmount
    return () => clearInterval(timerInterval);
  }
  }, [nextTour]);
  const getDetailsProduct = () => {
    Services.getAll(`products/${params.id}/details`)
      .then((res) => {
        setDetails(res?.data?.product);
        setBreadCrumbs([{name:res?.data?.product?.titre,id:res?.data?.product?.id}, ...res?.data?.breadcrumbs]);
        setDocs(
          res?.data?.product?.images
            ?.filter((i) => i?.type !== "image")
            ?.map((i) => {
              return { url: IMG_URL + i.path, name: i.type };
            })
        );
        setDesc(
          res?.data?.product?.product_option
            ?.filter((option) => option?.option === "Description")
            ?.map((option) => option?.value)[0]
        );
    getMyCart(res.data?.product)
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const addToCart = () => {
    const cart = {
      productId: details?.id,
      quantity: qte,
    };
    Services.create(cart, "panier/add")
      .then((res) => {
        setShowOffcanvas(!showOffcanvas);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMyCart = (details) => {
    Services.getAll("panier")
      .then((res) => {
        setCart(res?.data?.panier);
        setCartData(res?.data?.panier);

        const filteredProduct = res?.data?.panier?.panier_items?.find(
          (item) => item?.product_id === parseInt(params?.id)
        );
        if (filteredProduct) {
          // Set the filtered product in the state
          setProductInCart(filteredProduct?.quantity);
        }
       if(
        qte+filteredProduct?.quantity >= +details?.quantite ||
         qte+filteredProduct?.quantity  >= +details?.initial_quantity) {
          setQte(0)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMyAdresses = () => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
        const filteredAddresses = res?.data?.filter((adr) => adr?.type === 2);
        setMyAddress(filteredAddresses[0]);      
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {      
    const handleScroll = () => {
      const priceBlock = document.querySelector(".price-block");
      if (priceBlock) {
        const priceBlockOffset = priceBlock.offsetTop;
        setIsPriceBlockFixed(window.pageYOffset > priceBlockOffset);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function tempsRestantLivraison(datetime_end,startDeliveryDateTimeString) {
    // Convert the start delivery date string to a Date object
    const startDeliveryDateTime = new Date(datetime_end);

    // Get the current system date
    const currentDate = new Date();

    // Calculate the remaining time until the start delivery time
    const difference = startDeliveryDateTime - currentDate;

    // Check if the delivery time is in the future
    if (difference > 0) {
        // Convert milliseconds to hours and minutes
        const heuresRestantes = Math.floor(difference / (1000 * 60 * 60));
        const minutesRestantes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const secondsRestantes = Math.floor((difference % (1000 * 60)) / 1000);
        moment.locale('fr'); // Set the locale to French
        const deliveryDate = moment(startDeliveryDateTimeString).isSame(moment(), 'day') ? "aujourd'hui" : moment(startDeliveryDateTimeString).format('dddd');
        // Return the formatted remaining time
        return `Plus que ${heuresRestantes.toString().padStart(2, '0')}:${minutesRestantes.toString().padStart(2, '0')}:${secondsRestantes.toString().padStart(2, '0')} pour être livré ${deliveryDate} à partir de ${startDeliveryDateTime.getHours().toString().padStart(2, '0')}h:${startDeliveryDateTime.getMinutes().toString().padStart(2, '0')}`;

    } else {
        return `La date de livraison est déjà passée.`;
    }
}
const handleConnect=()=>{
  const path = window.location.pathname;
   localStorage.setItem('redirectAfterLogin', path);
  navigate("/login");
}
  useEffect(() => {
    getMyAdresses()
    getTours()
    getDetailsProduct();
    const auth = localStorage.getItem("token");
    if (auth) {
      const decodedToken = jwtDecode(auth);
      const currentTime = Date.now() / 1000;
      setConnected(decodedToken.exp < currentTime);
    } else {
      return setConnected(true);
    }
  }, []);

  return (
    <LayoutF>
      {modalAdr ? (
        <ModalAdr
          cart={cart}
          modalAdr={modalAdr}
          setModalAdr={setModalAdr}
          check={check}
          setCheck={setCheck}
          setAccessibleModal={setAccessibleModal}
          accessibleModal={accessibleModal}
        />
      ) : (
        ""
      )}
      {accessibleModal ? (
        <AccessibleOrNot
          accessibleModal={accessibleModal}
          setAccessibleModal={setAccessibleModal}
          check={check}
          connected={connected}
        />
      ) : (
        ""
      )}

      <div className="bg-auth">
        <div className="container ">
          {breadcrumbs && (
            <Crumb breadcrumb={breadcrumbs} />
          )}
          {showOffcanvas && (
            <CartToggle
              showOffcanvas={showOffcanvas}
              setShowOffcanvas={setShowOffcanvas}
              qte
              details
              cart={cart}
              setCart={setCart}
              getMyCart={getMyCart}
              tempsRestantLivraison={tempsRestantLivraison(nextTour?.datetime_end,nextTour?.livraison_heur_debut)}
            />
          )}
          <div className="row mt-32" style={{position:'relative'}}>
            <div className="col-md-9 row">
              <div className="col-md-6 white-bg">
                <div
                  className="img-product"
                  style={{
                    backgroundImage: `url(${
                      IMG_URL + details?.images[0]?.path
                    })`,
                  }}
                >
                  {/* <img
                  src={IMG_URL + details?.images[0]?.path}
                  className="w-100"
                  alt="product"
                /> */}
                  <div className="like-share-buttons">
                    {/* <button className="link-s details-btn">
                      <img src={Like} alt="like" />
                    </button> */}
                    <button className="link-s details-btn">
                      <img src={Share} alt="share" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <h6>{details?.titre}</h6>
                <p className="legende mt-8">Ref: {details?.reference}</p>
                {/* <p><div dangerouslySetInnerHTML={{ __html: details?.specification }}></div></p> */}
                {desc && (
                  <>
                    <ul>
                      {desc.split(".").map(
                        (line, index) =>
                          line.trim() && (
                            <li className="text-start" key={index}>
                              {line.trim()}
                            </li>
                          )
                      )}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className={`col-md-3 text-end fixed-block-price`} >
              <div className="add-to-cart bg-auth">
                {!connected && (myAddress!==undefined && (myAddress===true || myAddress)) ? (
                  <>
                    {" "}
                    <h6 className="bleu800">
                      {parseFloat(details?.ht).toFixed(2).replace('.', ',')} € HT{" "}
                      <span className="bleu800-body-s">/pièce</span>
                    </h6>
                    <p className="bleu700 Exergue">
                      {parseFloat(details?.ttc).toFixed(2).replace('.', ',')} € TTC{" "}
                      <span className="bleu700-body-s">/pièce</span>
                    </p>
                  </>
                ) : (
                  ""
                )}
                {localStorage.getItem("adr") && (
                  <>
                    <div className="d-flex">
                      <img src={Liv} alt="liv icon" className="mr-8" />{" "}
                      <p className="bleu800-body-s my-0">
                      {nextTour && tempsRestantLivraison(nextTour?.datetime_end,nextTour?.livraison_heur_debut)}
                      </p>
                    </div>
                    <div className="d-flex mt-16">
                      <img src={Check} alt="Check icon" className="mr-8" />
                      <p className="bleu700-body-s my-0">
                        {" "}
                        {details?.quantite} unités disponibles
                      </p>
                    </div>

                    <hr />
                  </>
                )}
                {!connected && (myAddress!==undefined && (myAddress===true || myAddress)) ? (
                  <>
                    <div className="d-flex p-1 qte-container">
                      <button
                        className="qte-btn p-2"
                        onClick={() => setQte(qte - 1)}
                        disabled={qte === 1 || qte===0}
                      >
                        -
                      </button>
                      <button className="link-s p-2">{qte}</button>
                      <button
                        className="qte-btn  p-2"
                        onClick={() => setQte(qte + 1)}
                        disabled={
                          qte >= +details?.quantite ||
                          qte+productInCart >= +details?.quantite ||
                          qte+productInCart >= details?.quantite
                          || (qte+productInCart  >= +details?.initial_quantity &&  details?.initial_quantity!=0)
                        }
                      >
                        +
                        {details?.initial_quantity==0}
                      </button>
                      
                    </div>
                    <button
                      className="cta-primary-s mt-8 w-100"
                      onClick={addToCart}
                     
                      // disabled={
                      //   qte >= +details?.quantite ||
                      //   qte+productInCart >= +details?.quantite ||
                      //   qte+productInCart >= details?.quantite
                      //   || qte+productInCart  >= +details?.initial_quantity
                      // }
                    >
                      Ajouter au panier
                    </button>
                    
                   <p className="mt-24 text-danger">{(qte+productInCart  >= +details?.initial_quantity &&  details?.initial_quantity!=0) ? 'Quantité maximum atteinte' :''}</p> 
                  </>
                ) : myAddress===undefined  || myAddress===null 
                    || localStorage.getItem('adr')===undefined|| localStorage.getItem('adr')===null ? (
                  <>
                    <p
                      className="bleu800 Exergue text-center"
                    >
                      Saisis ton adresse de livraison pour pouvoir ajouter au
                      panier
                    </p>
                    <div className="col mt-16 d-grid gap-2">
                      <button
                        className="cta-primary-s mt-8 hv-5 w-100"
                        size="lg"
                        onClick={() => setModalAdr(true)}
                      >
                        Je saisis mon adresse
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p
                      className="bleu800 Exergue text-center"
                     
                    >
                      Connecte-toi pour connaître les prix
                    </p>
                    <div className="col mt-16 d-grid gap-2">
                  
                      <Button
                        className="cta-primary-s mt-8 hv-5 w-100"
                        size="lg"
                        onClick={handleConnect}
                        // href="/login"
                      >
                        Se connecter
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-24 mb-60">
            <div className="col-md-8">
              <div className="spec">
                <p className="tech-title">Spécifications techniques</p>
                {details?.product_option.map((option, index) => (
                  <div
                    className={`row p-16-8 ${
                      index % 2 === 0 ? "white-row" : " grey-row"
                    }`}
                  >
                    <div className="col-3 text-start key-opt">
                      {option?.option}
                    </div>
                    <div className="col-9">
                      <p className=" value-opt">{option?.value}</p>
                    </div>
                  </div>
                ))}
                {details?.conseil && details?.conseil !== "null" && (
                  <>
                    <p className="tech-title mt-24">Conseil Marion</p>
                    <p className="mt-24">{details?.conseil}</p>
                  </>
                )}
                {docs.length > 0 && (
                  <>
                    <p className="tech-title mt-24">Documents techniques</p>
                    {docs.map((el) => (
                      <div className="row tech-doc mt-24">
                        <div className="col text-start">
                          {" "}
                          <span className="doc">
                            {" "}
                            <img src={file} alt="file icon" className="mr-8" />
                            {el?.name}
                          </span>{" "}
                        </div>
                        <div className="col text-end">
                          <a href={el.url} className="link-s" download>
                            <img
                              src={download}
                              alt="download icon"
                              className="mr-8"
                            />{" "}
                            Télécharger
                          </a>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutF>
  );
};
export default DetailsProduct;
