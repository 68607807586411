import { Card } from "react-bootstrap";
import "../assets/css/card.scss";
import ArrowRight from "../assets/imgs/fi_arrow-down.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
const CardProduct = ({ data }) => {
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const handleDetails = () => {
    navigate(`/product/${data.id}`);
  };
  const params = useParams();
  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      const decodedToken = jwtDecode(auth);
      const currentTime = Date.now() / 1000;
      setConnected(decodedToken.exp < currentTime);
    } else {
      return setConnected(true);
    }
  }, []);
  return (
    <div className="col-md-3 mb-4 link-cursor">
      <Card className="card-marion-product " onClick={handleDetails}>
        <div
          className="product-image-container"
          style={{ backgroundImage: `url(${IMG_URL + data?.images[0]?.path})` }}
        >
        </div>

        <Card.Body className="p-0 mt-16">
          <Card.Title>
            <p className="product-marque bleu600">{data?.product_marque?.name}</p>
            <p className="body-m title-product bleu800">{data?.titre}</p>
          </Card.Title>
          <Card.Text>
            <p className="legend bleu600"> Ref: {data?.reference}</p>
            {!connected && localStorage.getItem("role") == 2 ? (
              <p className="subtitle-m bleu800 mt-8">{parseFloat(data?.ht).toFixed(2).replace('.', ',')} € HT</p>
            ) : !connected && localStorage.getItem("role") == 1 ? (
              <p className="subtitle-m bleu800 mt-8">{parseFloat(data?.ttc)?.toFixed(2).replace('.', ',')} € TTC</p>
            ) : (
              ""
            )}
          </Card.Text>
          <a
            className="see-details px-0"
            href={`/product/${data?.id}`}
          >
            Voir le détail <img src={ArrowRight} alt="voir details icon" />
          </a>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardProduct;
